import dayjs from 'dayjs';
import { __ } from './i18n';
import { GROUP_RIDINGS } from './consts';

export const isProduction = () => {
    console.log('--> isProduction', import.meta.env.PROD);

    return import.meta.env.PROD;
};

export const Cookie = {
    tld: true, // if true, set cookie domain at top level domain
    set: function (name: string, value: string, days?: number) {
        const cookie: Record<string, unknown> = { [name]: value, path: '/' };

        if (days) {
            const date: Date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            cookie.expires = date.toUTCString();
        }

        if (Cookie.tld) {
            cookie.domain = '.' + window.location.hostname.split('.').slice(-2).join('.');
        }

        const arr = [];
        for (const key in cookie) {
            arr.push(`${key}=${cookie[key]}`);
        }
        document.cookie = arr.join('; ');

        return this.get(name);
    },

    getAll: function () {
        const cookie = {};
        document.cookie.split(';').forEach((el) => {
            const [k, v] = el.split('=');
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            cookie[k.trim()] = v;
        });
        return cookie;
    },

    get: function (name: string) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return this.getAll()[name];
    },

    delete: function (name: string) {
        this.set(name, '', -1);
    },
};

export const fixNumber = (number: number): string => {
    if (number < 10) {
        return `0${number}`;
    }

    return number.toString();
};

export const formatPhoneNumber = (phoneNumber: number | string): string => {
    const number = String(phoneNumber).replace(/\D/g, '');

    if (number.length !== 9) {
        return number;
    }

    const groupNumber = number.match(/^(\d{3})(\d{3})(\d{3})$/);

    return groupNumber ? groupNumber.slice(1).join(' ') : number;
};

export const formatNumberToTime = (number: number, version: 'long' | 'short' = 'long'): string => {
    const hours = Math.floor(number / 60);
    const minutes = number % 60;

    if (version === 'short') {
        return `${fixNumber(hours)}:${fixNumber(minutes)}`;
    }

    return `${hours} godz. ${fixNumber(minutes)} min.`;
};

export const isPageActive = (page: string): boolean => {
    return window.location.href.includes(page);
};

export const navigateHistoryBack = () => {
    window.history.back();
};

export const fromToDateFormat = (from: Date, to: Date): string => {
    if (dayjs(from).isSame(to, 'day')) {
        return `${dayjs(from).format('DD.MM.YYYY')} ${dayjs(from).format('HH:mm')} - ${dayjs(to).format('HH:mm')}`;
    }

    return `${dayjs(from).format('DD.MM.YYYY HH:mm')} - ${dayjs(to).format('DD.MM.YYYY HH:mm')}`;
};

export const isGroupRiding = (type: number): boolean => {
    return GROUP_RIDINGS.includes(type);
};
